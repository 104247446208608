import * as fetchActions from "./fetch-actions";
import _ from "lodash";
import filterService from "@/services/filter";
import router from "@/router/index";

import {
  isObject,
  isEmptyObjectCheck,
  getFilterQueryString,
  getFilterQueryStringWithoutArrayBasedOnSchema,
} from "@/helpers/helper";
// schema
// import jobFilterSchema from "@/schema/jobFilter";
import jobBoardSchema from "@/schema/jobBoardSchema";
import jobBoardSchemaForParentOrg from "@/schema/jobBoardSchemaForParentOrg";
import jobBoardSchemaForCandidate from "@/schema/jobBoardSchemaForCandidate";
import jobBoardPertempsSchema from "@/schema/jobBoardPertempsSchema";
import newCandidateListSchema from "@/schema/newCandidateListSchema";
import candidateListFacilityAdminSchema from "@/schema/candidateListFacilityAdminSchema";
import candidateListUKSchema from "@/schema/candidateListUKSchema";
import candidateListPertempsSchema from "@/schema/candidateListPertempsSchema";
import candidateListSchemaForParentOrg from "@/schema/candidateListSchemaForParentOrg";
import candidateListSchemaForTFW from "@/schema/candidateListSchemaForTFW";
import candidateListSchemaForIms from "@/schema/candidateListSchemaForIms";
import courseListSchemaForIMS from "@/schema/courseListSchemaForIMS";
import courseListSchemaForSLC from "@/schema/courseListSchemaForSLC";

// import candidateListSchema from "@/schema/candidateListSchema";
// import candidateFilterSchema from "@/schema/candidateFilter";

// existing filter dependency
import existingFilterGetter from "./existingFilterDependent/getter";

import {
  conditionalOptions,
  availabilityOptions,
  contractOptions,
  conditionalOptionWithNotSelected,
  activeOptions,
  publishedOptions,
  jobStatusOptions,
  jobAvailabilityOptions,
  isStudentOptions,
  isProfessional,
  isSponsored,
  eligible,
  fullPartTime,
} from "./helper";

import { Role } from "@/helpers/helper"
import isJobSupplier from "../../mixins/isJobSupplier";

const allFilterKeys = {
  candidateType: [],
  candidateLevel: [],
  candidateLevelJob: [],
  speciality: [],
  subSpeciality: [],
  recruitingCandidateLevel: [],
  jobStatus: [],
  locationLanguage: [],
  locationCountryInfo: [],
  locationRegion: [],
  skill: [],
  qualification: [],
  qualificationCountries: [],
  gender: [],
  specialInterest: [],
  licensingAuthority: [],
  titles: [],
  locations: [],
  birthPlaces: [],
  insideUAELocation: [],
  hospitalNames: [],
  conditionalOptions: [],
  availabilityOptions: [],
  contractOptions: [],
  ageLimit: [],
  postingDates: [], // convert this to date filter and add the required component
  salaryRange: [],
  voluntaryJobs: [],
  retiredJobs: [],
  regionInclude: [],
  regionExclude: [],
  hospitalInclude: [],
  hospitalExclude: [],
  searchTerm: [],
  retiredClinician: [],
  volunteer: [],
  licence: [],
  active: [],
  published: [],
  deleted: [],
  available: [],
  availabilityType: [],
  contractType: [],
  preferredLocations: [],
  nationalityInfo: [],
  specialitySearch: [],
  pqrCountries: [],
  pqrLicensingAuthority: [],
  pqrLevel: [],
  workingLevel: [],
  currentCountry: [],
  expiryFromTime: [],
  expiryToTime: [],
  organisation: [],
  partnerHospitals: [],
  partnerAgency: [],
  jobAvailability: [],
  languageQualification: [],
  languageQualLevel: [],
  nhsBand: [],
  qualificationClassificationCountries: [],
  qualificationLevel: [],
  experience: [],
  pqrHeader: [],
  pqrAHPSpeciality: [],
  AHPSpeciality: [],
  location___country_id__in: [],
  jobGroups: [],
  isStudent: [],
  isProfessional: [],
  courseTitle: [],
  isSponsored: [],
  studentType: [],
  courseType: [],
  orgDepartment: [],
  courseLevel: [],
  healthSector: [],
  studyMode: [],
  cpdCourse: [],
  eligible:[],
  fullPartTime:[],
};

// initial state
const state = () => ({
  // jobFilterSchema,
  // candidateFilterSchema,
  jobBoardSchema,
  jobBoardSchemaForParentOrg,
  jobBoardSchemaForCandidate,
  jobBoardPertempsSchema,
  newCandidateListSchema,
  candidateListFacilityAdminSchema,
  candidateListUKSchema,
  candidateListPertempsSchema,
  candidateListSchemaForParentOrg,
  candidateListSchemaForTFW,
  candidateListSchemaForIms,
  courseListSchemaForIMS,
  courseListSchemaForSLC,
  // candidateListSchema,
  allFiltersData: {
    ...allFilterKeys,
  },
  filterDependencyObjForAllSchemas: {},
  selectedFilters: allFilterKeys,
  isFetchingGenericFilter: false,
  currentPageSchema: "",
  currentPageDefaultQuery: {},
  isFilterLoaded: false,
  favouriteFilterList: [],
  isSelectedFilterUpdated: false
});

// create getters for all state variables
const getters = {
  ...existingFilterGetter,
  getCurrentPageDefaultQuery: (state) => state.currentPageDefaultQuery,
  allFiltersData: (state) => state.allFiltersData,
  jobBoardSchema: (state) => state.jobBoardSchema,
  jobBoardSchemaForParentOrg: (state) => state.jobBoardSchemaForParentOrg,
  jobBoardSchemaForCandidate: (state) => state.jobBoardSchemaForCandidate,
  jobBoardPertempsSchema: (state) => state.jobBoardPertempsSchema,
  candidateListSchemaForParentOrg: (state) => state.candidateListSchemaForParentOrg,
  candidateListSchemaForTFW: (state) => state.candidateListSchemaForTFW,
  candidateListSchemaForIms: (state) => state.candidateListSchemaForIms,
  courseListSchemaForIMS: (state) => state.courseListSchemaForIMS,
  courseListSchemaForSLC: (state) => state.courseListSchemaForSLC,
  newCandidateListSchema: (state) => state.newCandidateListSchema,
  candidateListFacilityAdminSchema: (state) => state.candidateListFacilityAdminSchema,
  candidateListUKSchema: (state) => state.candidateListUKSchema,
  candidateListPertempsSchema: (state) => state.candidateListPertempsSchema,
  candidateListSchema: (state) => state.candidateListSchema,
  selectedFilters: (state) => state.selectedFilters,
  filterDependencyObj: (state) =>
    state.filterDependencyObjForAllSchemas[state.currentPageSchema],
  isFetchingGenericFilter: (state) => state.isFetchingGenericFilter,
  currentPageSchema: (state, getters, rootState) => {
    const { getRoleScopes, jobBoardSchema, jobBoardSchemaForParentOrg, jobBoardSchemaForCandidate, newCandidateListSchema, candidateListFacilityAdminSchema, candidateListUKSchema, candidateListPertempsSchema, courseListSchemaForIMS, courseListSchemaForSLC } = getters
    if (state.currentPageSchema === "jobBoardSchema") {
      return jobBoardSchema;
    }
    if (state.currentPageSchema === "jobBoardSchemaForParentOrg") {
      return jobBoardSchemaForParentOrg;
    }
    if (state.currentPageSchema === "jobBoardSchemaForCandidate") {
      return jobBoardSchemaForCandidate;
    }
    if (state.currentPageSchema === "jobBoardPertempsSchema") {
      return jobBoardPertempsSchema;
    }
    if (state.currentPageSchema === "newCandidateListSchema") {
      const { getIsParentUser } = getters
      const getRole = getRoleScopes.length ? getRoleScopes[0] : "";
      if (getIsParentUser) {
        return candidateListSchemaForParentOrg
      } else if ([Role.customerAdmin, Role.customerRecruiter].includes(getRole)) {
        return candidateListFacilityAdminSchema;
      }
      return newCandidateListSchema;
    }
    if (state.currentPageSchema == "candidateListUKSchema") {
      return candidateListUKSchema;
    }
    if (state.currentPageSchema == "candidateListPertempsSchema") {
      return candidateListPertempsSchema;
    }
    if (state.currentPageSchema == "candidateListSchemaForParentOrg") {
      return candidateListSchemaForParentOrg;
    }
    if (state.currentPageSchema == "candidateListSchemaForTFW")
      return candidateListSchemaForTFW;
    if (state.currentPageSchema == "candidateListSchemaForIms")
      return candidateListSchemaForIms;
    if (state.currentPageSchema == "courseListSchemaForIMS")
      return courseListSchemaForIMS
    if (state.currentPageSchema == "courseListSchemaForSLC")
      return courseListSchemaForSLC
  },
  getQueryResult: (state) => {
    const { current: { name: pageName, path } } = router.history;
    const {
      selectedFilters,
      currentPageSchema,
      getCurrentPageDefaultQuery,
    } = state;
    const selectedData = { ...getCurrentPageDefaultQuery };
    let all_agency_ids
    let queryUrl
    let routeUrl
    for (const item in selectedFilters) {
      let data = selectedFilters[item];
      // based on schema multiselect: true we will get as array or will gat as object
      if (typeof data === "string" && data) {
        selectedData[item] = [data];
      } else if (Array.isArray(data) && data.length) {
        selectedData[item] = data.map((val) => {
          if (isObject(val) && !isEmptyObjectCheck(val)) {
            if (item === "languageQualLevel") {
              return encodeURIComponent(val.label)
            }
            if (["candidateListPertempsSchema", "jobBoardPertempsSchema"].includes(currentPageSchema) && ["partnerAgency", "partnerHospitals"].includes(item)) {
              if (val?.code == 0) { // check for "All" selected
                all_agency_ids = state.allFiltersData[item] ? _(state.allFiltersData[item]).filter((val) => val?.code != 0).map((val) => val?.code).value() : []
                return val?.code
              }
            }
            return val.code
          } else {
            return val
          }
        });
      } else if (isObject(data) && !isEmptyObjectCheck(data)) {
        selectedData[item] = [data["code"]];
      }
    }
    // Check for Pertemps Partner Agency (organisation) Select
    if (currentPageSchema === "candidateListPertempsSchema" && selectedFilters["partnerAgency"] && selectedData["partnerAgency"]) {
      if (selectedData["partnerAgency"][0] == 0) { // check for "All" selected
        routeUrl = getFilterQueryString(selectedData, state[currentPageSchema]);
        selectedData["partnerAgency"] = all_agency_ids
        queryUrl = getFilterQueryStringWithoutArrayBasedOnSchema(
          pageName,
          selectedData,
          state[currentPageSchema],
        );
      } else {
        routeUrl = getFilterQueryString(selectedData, state[currentPageSchema]);
        queryUrl = getFilterQueryStringWithoutArrayBasedOnSchema(
          pageName,
          selectedData,
          state[currentPageSchema],
        );
      }
    } if (currentPageSchema === "jobBoardPertempsSchema" && selectedFilters["partnerHospitals"] && selectedData["partnerHospitals"]) {
      if (selectedData["partnerHospitals"][0] == 0) { // check for "All" selected
        routeUrl = getFilterQueryString(selectedData, state[currentPageSchema]);
        selectedData["partnerHospitals"] = all_agency_ids
        queryUrl = getFilterQueryStringWithoutArrayBasedOnSchema(
          pageName,
          selectedData,
          state[currentPageSchema],
        );
      } else {
        routeUrl = getFilterQueryString(selectedData, state[currentPageSchema]);
        queryUrl = getFilterQueryStringWithoutArrayBasedOnSchema(
          pageName,
          selectedData,
          state[currentPageSchema],
        );
      }
    } else {
      routeUrl = getFilterQueryString(selectedData, state[currentPageSchema]);
      let queryData = { ...selectedData };
      if (queryData?.organisation && queryData?.organisation?.includes(0))
        delete queryData.organisation
      queryUrl = getFilterQueryStringWithoutArrayBasedOnSchema(
        pageName,
        queryData,
        state[currentPageSchema],
      );
    }
    return { routeUrl, queryUrl };
  },
  favouriteFilters: (state, getters) => {
    const { current: { path } } = router.history;
    const { getRoleScopes } = getters
    let data = state.favouriteFilterList || []
    if (getRoleScopes[0] == Role.customerCandidate || getRoleScopes[0] == Role.systemCandidate && path == "/jobs-list") {
      data.unshift({
        filter_name: "Favourite Jobs",
        isDefault: true,
        url: "/jobs-list?preferredType=%5Bfavourite%5D"
      }, {
        filter_name: "Applied Jobs",
        isDefault: true,
        url: "/jobs-list?preferredType=%5Bapplied%5D"
      }, {
        filter_name: "List All Jobs",
        isDefault: true,
        url: "/jobs-list"
      })
    }
    return data
  },
  isSelectedFilterUpdated: (state) => state.isSelectedFilterUpdated,
  currentPageSchemaName: (state) => state.currentPageSchema,
};

// Vue actions for fetching and manipulating the data
const actions = {
  ...fetchActions,
  dynamicSearchAction: ({ commit, state, dispatch, getters }, { query, loading, actionName, search }) => {
    loading(true);
    if (search) {
      return dispatch(actionName, query).then((res) => {
        loading(false);
      })
    } else {
      if (actionName === "genericSpecialitySearch") {
        commit("SET_GENERIC_SPECIALITY_SEARCH", []);
        return
      }
      loading(false);
    }
  },
  updateSelectedFilterFromFilterCode(
    { commit, state, dispatch, getters },
    payload
  ) {
    let filterEl = {};
    const { currentPageSchema } = getters;
    const entries = Object.entries(payload)
    let isSelectedFromQueryCheck = false;
    try {
      for (let [key, valueInPayload] of entries) {
        // if (key !== "searchTerm" && !state.allFiltersData[key]){
        //   return;
        // } 
        let valArray;
        if (key === "specialitySearch") {
          valArray = valueInPayload.replace(/]|\[/g, "").split(/(\(.*?\))/g).filter((val) => {
            if (val && val !== ",") {
              return val;
            }
          })
        }
        // Omit True or False
        // Regex checking Square brackets , Characters, Underscore, Space
        else if (valueInPayload != '[true]' && valueInPayload != '[false]'
        && /\[([a-zA-Z_\s]+)\]/.test(valueInPayload)) {
          valArray = [valueInPayload.replace(/]|\[/g, "")];
        } else {
          valArray = JSON.parse(valueInPayload);
        }
        if (key === "pqrCountries") {
          let queryName = currentPageSchema["pqrCountries"]['queryKey']
          let ids = JSON.parse(payload["pqrCountries"])
          if (ids.length) {
            dispatch("pqrHeaderLicensingAuthorities", `${queryName}=${ids}`)
            dispatch("pqrHeader", `country_id=${ids}`)
            // dispatch("pqrLicensingLevel", { country_id__in: ids })
            dispatch("pqrWorkingLevel", { country_id__in: ids })
            dispatch("pqrAHPSpeciality", { pqr_country_id: ids });
          }
        }
        // if(key === "pqrHeader"){
        //   const pqr_country_id = JSON.parse(payload["pqrCountries"]);
        //   const pqr_header_id = JSON.parse(payload["pqrHeader"]);
        //   dispatch("pqrAHPSpeciality", { pqr_country_id, pqr_header_id });
        // }

        if (key === "languageQualification") {
          let qualification_id = JSON.parse(payload["languageQualification"])
          if (qualification_id.length) {
            dispatch("scoreForNonMedQualID", qualification_id)
          }
        }
        if (key === "qualificationClassificationCountries") {
          let country_id = JSON.parse(payload["qualificationClassificationCountries"]);
          let candidate_type_id = JSON.parse(payload["candidateType"]);
          if (country_id.length && candidate_type_id.length) {
            dispatch("getQualificationClassificationLevel", { country_id, candidate_type_id })
          }
        }

        if (key === "studentType") {
          let student_type_id = JSON.parse(payload["studentType"]);
          if (student_type_id.length) {
            dispatch("genericCourseLevel", student_type_id)
          }
        }
        if (valArray && valArray.length) {
          isSelectedFromQueryCheck = true;
          if (currentPageSchema && currentPageSchema[key] && currentPageSchema[key]["component"] === "filterRange") {
            filterEl = {
              name: key,
              value: valArray,
            };
          } else if (currentPageSchema && currentPageSchema[key] && currentPageSchema[key]["component"] === "filterDatePicker") {
            filterEl = {
              name: key,
              value: valArray,
            };
          } else if (key == "searchTerm") {
            filterEl = {
              name: key,
              value: valArray
            }
          }  else if (key == "specialitySearch" && currentPageSchema && currentPageSchema[key] && currentPageSchema[key]["component"] === "accordionSelect") {
            filterEl = {
              name: key,
              value: valArray.map((flCode) => {
                if (state.allFiltersData[key]) {
                  const obj = state.allFiltersData[key].find(
                    (obj) => {
                      if (flCode === null) {
                        return obj.code === 'null'; // Handled Null case on Refresh Dont Remove this
                      }
                      return obj.code === flCode
                    }
                  );
                  const code = obj?.code
                    .replace(/[\(\)]/g, "")
                    .split(",")
                    .map(Number);
                  obj.label =
                    code[0] === obj?.speciality_id
                      ? obj?.speciality
                      : obj?.sub_speciality;
                  return obj;
                }
              })
            }
          } else if (currentPageSchema && currentPageSchema[key] && currentPageSchema[key]["component"] === "filterDateInput") {
            filterEl = {
              name: key,
              value: valArray,
            };
          }
          else {
            filterEl = {
              name: key,
              value: valArray.map((flCode) => {
                if (state.allFiltersData[key]) {
                  const obj = state.allFiltersData[key].find(
                    (obj) => {
                      if (flCode === null) {
                        return obj.code === 'null'; // Handled Null case on Refresh Dont Remove this
                      }
                      return obj.code === flCode
                    }
                  );
                  return obj;
                }
              }),
            };
          }
          commit("UPDATE_SELECTED_FILTER", filterEl); // trigger api after this commit
        }
      }
      commit("SET_SELECTED_FILTER_UPDATED", true);
    } catch (e) {
      console.log("error in function updateSelectedFilterFromFilterCode ", e);
    }
    return isSelectedFromQueryCheck;
  },

  updateSelectedFilter: ({ commit, state, getters, dispatch }, payload) => {
    const { currentPageSchema, getIsParentUser, selectedFilters } = getters;
    if (getIsParentUser) {
      if (["partnerAgency", "partnerHospitals"].includes(payload.name)) {
        dispatch("clearAllSelectedFilters")
      }
    } else {
      if (payload.name === "candidateType") {
        dispatch("clearAllSelectedFilters")
      }
    }
    if (["partnerAgency", "partnerHospitals"].includes(payload.name) && payload?.value?.code == 0 && !['candidateListPertempsSchema', 'jobBoardPertempsSchema'].includes(state.currentPageSchema)) {
      dispatch("clearAllSelectedFilters")
      return;
    }
    if (
      currentPageSchema[payload.name] &&
      currentPageSchema[payload.name]["component"] === "filterRange"
    ) {
      commit("UPDATE_SELECTED_FILTER", {
        ...payload,
        value: payload["value"] ? [payload["value"]] : [],
      });
      // trigger api after this commit
    } else if (payload.type === "searchTerm") {
      commit("UPDATE_SELECTED_FILTER", {
        ...payload,
        name: "searchTerm",
        value: payload["value"],
      });
    } else if (
      currentPageSchema[payload.name] && currentPageSchema[payload.name]["component"] === "filterSelect"
    ) {
      if (payload.name === "pqrCountries") {
        let queryName = currentPageSchema[payload.name]['queryKey']
        let ids = Array.isArray(payload?.value) ? payload?.value?.map((val) => val?.code) : payload?.value?.code;
        if (ids?.length) {
          dispatch("pqrHeaderLicensingAuthorities", `${queryName}=${ids}`)
          dispatch("pqrHeader", `country_id=${ids}`)
          // dispatch("pqrLicensingLevel", { country_id__in: ids })
          dispatch("pqrWorkingLevel", { country_id__in: ids })
          const queryPayload = { pqr_country_id: ids }
          dispatch("pqrAHPSpeciality", queryPayload);
        }
      }
      // if(payload.name === "pqrHeader"){
      //   const { pqrCountries, pqrHeader } = selectedFilters;
      //   let queryPayload = {};
      //   if(pqrCountries){
      //     const pqr_country_id = Array.isArray(pqrCountries) ? pqrCountries.map((val) => val?.code) : pqrCountries?.code;
      //     queryPayload = { ...queryPayload, pqr_country_id };
      //   }
      //   if(pqrHeader){
      //     const pqr_header_id = Array.isArray(pqrHeader) ? pqrHeader.map((val) => val?.code) : pqrHeader?.code;
      //     queryPayload = { ...queryPayload, pqr_header_id };
      //   }
      //   dispatch("pqrAHPSpeciality", queryPayload);
      // }
      if (payload.name === "languageQualification") {
        let qualification_id = Array.isArray(payload.value) ? payload.value.map((val) => val?.code) : payload.value?.code;
        if (qualification_id?.length) {
          dispatch("scoreForNonMedQualID", qualification_id)
        }
      }
      if (payload.name === "qualificationClassificationCountries") {
        let country_id = Array.isArray(payload.value) ? payload.value.map((val) => val?.code) : payload?.value?.code;
        if (country_id) {
          const { candidateType } = selectedFilters;
          if (candidateType) {
            const candidate_type_id = Array.isArray(candidateType) ? candidateType.map((val) => val?.code) : candidateType?.code;
            dispatch("getQualificationClassificationLevel", { country_id, candidate_type_id });
          }

        }
      }
      commit("UPDATE_SELECTED_FILTER", payload);
      commit("CLEAR_DEPENDENT_FILTERS", payload);
      commit("CLEAR_API_DEPENDENT_FILTERS", payload);
    } else if (
      currentPageSchema[payload.name] && currentPageSchema[payload.name]["component"] === "customSelectOption"
    ) {
      commit("UPDATE_SELECTED_FILTER", payload);
      commit("CLEAR_DEPENDENT_FILTERS", payload);
    } else if (currentPageSchema && currentPageSchema[payload.name] && currentPageSchema[payload.name]["component"] === "filterDatePicker") {
      commit("UPDATE_SELECTED_FILTER", payload);
      commit("CLEAR_DEPENDENT_FILTERS", payload);
    } else if (
      currentPageSchema[payload.name] && currentPageSchema[payload.name]["component"] === "accordionSelect"
    ) {
      commit("UPDATE_SELECTED_FILTER", payload);
      commit("CLEAR_DEPENDENT_FILTERS", payload);
    } else if (currentPageSchema[payload.name] && currentPageSchema[payload.name]["component"] === "filterDateInput") {
      commit("UPDATE_SELECTED_FILTER", payload);
      commit("CLEAR_DEPENDENT_FILTERS", payload);
    }
    commit("SET_GENERIC_SPECIALITY_SEARCH", []);
  },
  updateSearchQueryFilter: ({ commit, state, dispatch }, payload) => {
    console.log(payload);
  },
  clearSearchFilterWithUpdateSearch: ({ commit, state, dispatch }, payload) => {
    payload["type"] = "searchTerm";
    dispatch("updateSelectedFilter", payload);
  },
  clearAllSelectedFilters: ({ commit }) => {
    commit("CLEAR_ALL_SELECTED_FILTERS");
  },
  setCurrentPageSchema: ({ commit }, payload) => {
    commit("SET_CURRENTPAGE_SCHEMA", payload);
    return
  },
  setCurrentPageDefaultQuery: ({ commit }, payload) => {
    commit("SET_CURRENTPAGE_DEFAULT_QUERY", payload);
  },
  initAllFilters: async ({ commit, state, dispatch, getters }) => {
    let appendFilterAction = [];
    commit("SET_GENERIC_FETCHING", true);
    commit("SET_SELECTED_FILTER_UPDATED", false);
    const { currentPageSchema } = getters;
    const filterSchema = state.currentPageSchema
    if (['courseListSchemaForIMS', 'courseListSchemaForSLC'].includes(filterSchema)) {
      if (!state.isFilterLoaded) {
        appendFilterAction.push(dispatch("genericStudentType"));
        appendFilterAction.push(dispatch("genericCourseTestType"));
        appendFilterAction.push(dispatch("genericOrgDepartment"));
        appendFilterAction.push(dispatch("genericHealthSector"));
        appendFilterAction.push(dispatch("genericCourseStudyMode"));
        appendFilterAction.push(dispatch("genericLocationLanguage"));
        commit("SET_GENERIC_CPD_COURSE", cpd_points());
      }
    } else {
      appendFilterAction.push(dispatch("getGenericSpecialitySearchBasedOnId"));
      appendFilterAction.push(dispatch("getOrganisationBasedLocation"));
      if (!state.isFilterLoaded) {
        appendFilterAction.push(dispatch("genericCandidateLevel"));
        appendFilterAction.push(dispatch("genericCandidateType"));
        appendFilterAction.push(dispatch("genericGetSpeciality"));
        appendFilterAction.push(dispatch("genericSubSpeciality"));
        // appendFilterAction.push(dispatch("genericGetLocations"));
        appendFilterAction.push(dispatch("genericGetHospitals"));
        appendFilterAction.push(dispatch("genericLocationRegion"));
        appendFilterAction.push(dispatch("genericGender"));
        appendFilterAction.push(dispatch("pqrHeaderCountries"));
        appendFilterAction.push(dispatch("nonMedicalQualNames"));
        appendFilterAction.push(dispatch("genericNHSBands"));
        appendFilterAction.push(dispatch("fetchJobGroups"));
        appendFilterAction.push(dispatch("genericCourseTitle"));

        const pqrCountriesQuery = router.history.current.query.pqrCountries
        if (pqrCountriesQuery) {
          let queryName = currentPageSchema["pqrCountries"]['queryKey']
          let ids = JSON.parse(pqrCountriesQuery)
          if (ids.length) {
            await appendFilterAction.push(dispatch("pqrHeaderLicensingAuthorities", `${queryName}=${ids}`))
            await appendFilterAction.push(dispatch("pqrHeader", `country_id=${ids}`))
            // await appendFilterAction.push(dispatch("pqrLicensingLevel", { country_id__in: ids }))
            await appendFilterAction.push(dispatch("pqrWorkingLevel", { country_id__in: ids }))
            await appendFilterAction.push(dispatch("pqrAHPSpeciality", { pqr_country_id: ids }))
          }
        }
        const languageQuery = router.history.current.query.languageQualification
        if (languageQuery) {
          let qualification_id = JSON.parse(languageQuery)
          if (qualification_id?.length)
            await appendFilterAction.push(dispatch("scoreForNonMedQualID", qualification_id))
        }
        const classificationQuery = router.history.current.query.qualificationClassificationCountries;
        const candidateTypeQuery = router.history.current.query.candidateType;
        if (classificationQuery && candidateTypeQuery) {
          let country_id = JSON.parse(classificationQuery)
          let candidate_type_id = JSON.parse(candidateTypeQuery)
          if (country_id?.length && candidate_type_id.length)
            await appendFilterAction.push(dispatch("getQualificationClassificationLevel", { country_id, candidate_type_id }))
        }

        // appendFilterAction.push(dispatch("jobStatus"));
        //appendFilterAction.push(dispatch("locationLanguage"));
        appendFilterAction.push(dispatch("genericLocationLanguage"));
        appendFilterAction.push(dispatch("genericLocationRegionCountryInfo"));
        // appendFilterAction.push(dispatch("skill"));
        // appendFilterAction.push(dispatch("specialInterest"));
        // appendFilterAction.push(dispatch("qualification"));
        // appendFilterAction.push(dispatch("gender"));
        // appendFilterAction.push(dispatch("licensingAuthority"));
        commit("SET_GENERIC_VOLUNTARY_JOB", conditionalOptions);
        commit("SET_GENERIC_RETIRED_JOB", conditionalOptions);
        commit("SET_GENERIC_RETIRED_CLINICIAN", conditionalOptionWithNotSelected);
        commit("SET_GENERIC_VOLUNTEER", conditionalOptionWithNotSelected);
        commit("SET_GENERIC_LICENCE", conditionalOptionWithNotSelected);
        commit("SET_GENERIC_ACTIVE", activeOptions);
        commit("SET_GENERIC_IS_STUDENT", isStudentOptions);
        commit("SET_GENERIC_IS_PROFESSIONAL", isProfessional)
        commit("SET_GENERIC_PUBLISHED", publishedOptions);
        commit("SET_GENERIC_DELETED", conditionalOptions);
        commit("SET_GENERIC_AVAILABLE", conditionalOptionWithNotSelected);
        commit("SET_GENERIC_AVAILABILITY_TYPE", availabilityOptions);
        commit("SET_GENERIC_CONTRACT_TYPE", contractOptions);
        commit("SET_GENERIC_JOB_STATUS", jobStatusOptions);
        commit("SET_GENERIC_JOB_AVAILABILITY_TYPE", jobAvailabilityOptions);
        commit("SET_GENERIC_IS_SPONSORED", isSponsored);
        commit("SET_GENERIC_ELIGIBLE",eligible)
        commit("SET_GENERIC_FULLPARTTIME",fullPartTime)
      }
    }

    return Promise.all(appendFilterAction)
      .then((res) => {
        commit("SET_GENERIC_FETCHING", false);
        commit("SET_FILTERS_LOADED", true);
        commit("SET_DEPENDENCY_FILTER");
        return res;
      })
      .catch((err) => {
        commit("SET_GENERIC_FETCHING", false);
        console.log("error occured while fetching all filters", err);
        return err;
      }).finally(() => {
        commit("SET_GENERIC_FETCHING", false);
      })
  },
  addFavouriteFilter: ({ commit, state, dispatch, getters }, payload) => {
    const { getUserId: userId } = getters;
    let { page_name } = payload;
    dispatch("showToast", { message: 'Saving...' });
    return filterService
      .addFavouriteFilter({ payload, userId })
      .then(res => {
        dispatch("showToast", {
          class: 'bg-success text-white',
          message: 'Favourite Filter Added successfully'
        });
        dispatch("fetchFavouriteFilter", page_name);
        return res;
      })
      .catch(err => {
        let msg = "Error while Adding";
        console.log("error add favourite filter", err);
        dispatch("showToast", { class: 'bg-danger text-white', message: msg })
        return err;
      });
  },
  updateFavouriteFilter: ({ commit, state, dispatch, getters }, data) => {
    const { getUserId: userId } = getters;
    let { payload, filter_bookmark_id } = data;
    let { page_name } = payload;
    dispatch("showToast", { message: 'Updating...' });
    return filterService
      .updateFavouriteFilter({ payload, filter_bookmark_id, userId })
      .then(res => {
        dispatch("showToast", {
          class: 'bg-success text-white',
          message: 'Favourite Filter Updated successfully'
        });
        dispatch("fetchFavouriteFilter", page_name);
        return res;
      })
      .catch(err => {
        let msg = "Error while Updating";
        console.log("error update favourite filter", err);
        dispatch("showToast", { class: 'bg-danger text-white', message: msg })
        return err;
      });
  },
  deleteFavouriteFilter: ({ commit, state, dispatch, getters }, data) => {
    const { filter_bookmark_id, page_name } = data;
    const { getUserId: userId } = getters;
    dispatch("showToast", { message: 'Deleting...' });
    return filterService
      .deleteFavouriteFilter({ filter_bookmark_id, userId })
      .then(res => {
        dispatch("showToast", {
          class: 'bg-success text-white',
          message: 'Favourite Filter Deleted successfully'
        });
        dispatch("fetchFavouriteFilter", page_name);
        return res;
      })
      .catch(err => {
        let msg = "Error while Delete";
        console.log("error delete favourite filter", err);
        dispatch("showToast", { class: 'bg-danger text-white', message: msg })
        return err;
      });
  }
};

// mutations
const mutations = {
  ["SET_FILTERS_LOADED"](state, val) {
    state.isFilterLoaded = val;
  },
  ["UPDATE_SELECTED_FILTER"](state, { name, value, type }) {
    state.selectedFilters[name] = value
  },
  ["CLEAR_ALL_FILTER_UPDATE_SEARCH_FILTER"](state, { name, value, type }) {
    const clearSelectedFilters = { ...state.selectedFilters };
    Object.entries(clearSelectedFilters).forEach(([key, value]) => {
      clearSelectedFilters[key] = [];
    });
    state.selectedFilters = { ...clearSelectedFilters, searchTerm: value };
  },
  ["CLEAR_ALL_SELECTED_FILTERS"](state) {
    const clearSelectedFilters = { ...state.selectedFilters };
    Object.entries(clearSelectedFilters).forEach(([key, value]) => {
      clearSelectedFilters[key] = [];
    });
    state.selectedFilters = clearSelectedFilters;
  },
  ["CLEAR_DEPENDENT_FILTERS"](state, { name }) {
    const currentSchema = state[state.currentPageSchema];
    if (
      currentSchema &&
      currentSchema[name] &&
      currentSchema[name].dependentFilter
    ) {
      const dependentFiltersList = currentSchema[name].dependentFilter;
      dependentFiltersList.forEach((fl) => {
        state.selectedFilters[fl] = [];
        const secondLevelDependent = currentSchema[fl]?.secondLevelDependentClear;
        if (secondLevelDependent)
          secondLevelDependent.forEach(secondLevel => {
            state.selectedFilters[secondLevel] = [];
          });
      });
    }
  },
  ["CLEAR_API_DEPENDENT_FILTERS"](state, { name }) {
    const currentSchema = state[state.currentPageSchema];
    if (
      currentSchema &&
      currentSchema[name] &&
      currentSchema[name].apiDependentFilter
    ) {
      const dependentFiltersList = currentSchema[name].apiDependentFilter;
      dependentFiltersList.forEach((fl) => {
        state.selectedFilters[fl] = [];
      });
    }
  },
  ["SET_CURRENTPAGE_SCHEMA"](state, { schema }) {
    state.currentPageSchema = schema;
  },
  ["SET_CURRENTPAGE_DEFAULT_QUERY"](state, payload) {
    state.currentPageDefaultQuery = payload;
  },
  ["SET_DEPENDENCY_FILTER"](state) {
    const computedDependencyObj = computeFilterDependency(
      state.allFiltersData,
      state
    );
    state.filterDependencyObjForAllSchemas = computedDependencyObj;
    if (state.filterDependencyObjForAllSchemas[state.currentPageSchema]['specialitySearch']) {
      state.filterDependencyObjForAllSchemas[state.currentPageSchema]['specialitySearch']['defaultEntries'] = [];
    }

  },

  ["SET_GENERIC_SPECIALITY_SEARCH"](state, payload) {
    if (state.filterDependencyObjForAllSchemas[state.currentPageSchema] && state.filterDependencyObjForAllSchemas[state.currentPageSchema]['specialitySearch']) {
      state.filterDependencyObjForAllSchemas[state.currentPageSchema]['specialitySearch']['defaultEntries'] = payload || [];
    }
  },
  ["SET_INIT_GENERIC_SPECIALITY_SEARCH"](state, data) {
    state.allFiltersData.specialitySearch = data;
  },
  ["SET_GENERIC_TITLES"](state, payload) {
    state.allFiltersData.titles = payload;
  },
  ["SET_GENERIC_VOLUNTARY_JOB"](state, payload) {
    state.allFiltersData.voluntaryJobs = payload;
  },
  ["SET_GENERIC_RETIRED_JOB"](state, payload) {
    state.allFiltersData.retiredJobs = payload;
  },
  ["SET_GENERIC_HOSPITALS"](state, payload) {
    state.allFiltersData.hospitalNames = payload;
    state.allFiltersData.organisation = [...payload, { label: "All", code: 0 }];
    const partnerHospitals = payload.filter(({ organisation_type_id }) => organisation_type_id != 13) || []
    state.allFiltersData.partnerHospitals = [...partnerHospitals, { label: "All", code: 0 }];
    const partnerAgency = payload.filter(({ organisation_type_id }) => organisation_type_id === 13) || []
    state.allFiltersData.partnerAgency = [...partnerAgency, { label: "All", code: 0 }];
    state.allFiltersData.hospitalInclude = payload;
    state.allFiltersData.hospitalExclude = payload;
  },
  ["SET_GENERIC_LOCATIONS"](state, payload) {
    state.allFiltersData.locations = payload;

    //state.allFiltersData.locationRegion = payload;
    state.allFiltersData.regionInclude = payload;
    state.allFiltersData.regionExclude = payload;
    //state.allFiltersData.preferredLocations = payload;
  },
  ["SET_GENERIC_BIRTH_PLACE"](state, payload) {
    state.allFiltersData.birthPlaces = payload;
    state.allFiltersData.currentCountry = payload;
    state.allFiltersData.qualificationCountries = payload;
  },
  ["SET_GENERIC_PREFERRED_LOCATIONS"](state, payload) {
    state.allFiltersData.preferredLocations = payload;
  },
  ["SET_GENERIC_PQR_HEADER_COUNTRIES"](state, payload) {
    state.allFiltersData.pqrCountries = payload;
  },
  ["SET_NON_MEDICAL_QUALIFICATION_OPTIONS"](state, payload) {
    state.allFiltersData.languageQualification = payload;
  },
  ["SET_NON_MEDICAL_QUALIFICATION_SCORES"](state, payload) {
    state.allFiltersData.languageQualLevel = payload;
    if (state.filterDependencyObjForAllSchemas[state.currentPageSchema]?.languageQualLevel?.defaultEntries) {
      state.filterDependencyObjForAllSchemas[state.currentPageSchema]['languageQualLevel']['defaultEntries'] = payload
    }
  },
  ["SET_GENERIC_PQR_HEADER_LICENSING_AUTHORITIES"](state, payload) {
    state.allFiltersData["pqrLicensingAuthority"] = payload;
    if (state.filterDependencyObjForAllSchemas[state.currentPageSchema]?.pqrLicensingAuthority?.defaultEntries) {
      state.filterDependencyObjForAllSchemas[state.currentPageSchema]['pqrLicensingAuthority']['defaultEntries'] = payload
    }
  },
  ["SET_GENERIC_PQR_HEADER"](state, payload) {
    state.allFiltersData["pqrHeader"] = payload;
    if (state.filterDependencyObjForAllSchemas[state.currentPageSchema]?.pqrHeader?.defaultEntries) {
      state.filterDependencyObjForAllSchemas[state.currentPageSchema]['pqrHeader']['defaultEntries'] = payload
    }
  },
  ["SET_GENERIC_PQR_AHP_SPECIALITY"](state, payload) {
    state.allFiltersData["pqrAHPSpeciality"] = payload;
    if (state.filterDependencyObjForAllSchemas[state.currentPageSchema]?.pqrAHPSpeciality?.defaultEntries) {
      state.filterDependencyObjForAllSchemas[state.currentPageSchema]['pqrAHPSpeciality']['defaultEntries'] = payload
    }
  },
  ["SET_GENERIC_PQR_HEADER_LICENSING_LEVEL"](state, payload) {
    state.allFiltersData["pqrLevel"] = payload;
    if (state.filterDependencyObjForAllSchemas[state.currentPageSchema]?.pqrLevel?.defaultEntries) {
      state.filterDependencyObjForAllSchemas[state.currentPageSchema]['pqrLevel']['defaultEntries'] = payload
    }
  },
  ["SET_GENERIC_PQR_HEADER_WORKING_LEVEL"](state, payload) {
    state.allFiltersData["workingLevel"] = payload;
    if (state.filterDependencyObjForAllSchemas[state.currentPageSchema]?.workingLevel?.defaultEntries) {
      state.filterDependencyObjForAllSchemas[state.currentPageSchema]['workingLevel']['defaultEntries'] = payload
    }
  },
  ["SET_GENERIC_UAE_LOCATIONS"](state, payload) {
    state.allFiltersData.insideUAELocation = payload;
  },
  ["SET_GENERIC_HOSPITAL_LIST"](state, payload) {
    state.allFiltersData.hospitalNames = payload;
  },
  ["SET_GENERIC_CANDIDATE_LEVEL"](state, data) {
    state.allFiltersData.candidateLevel = data;
  },
  ["SET_GENERIC_CANDIDATE_LEVEL_JOB"](state, data) {
    state.allFiltersData.candidateLevelJob = data;
  },
  ["SET_GENERIC_RECRUITING_CANDIDATE_LEVEL"](state, data) {
    state.allFiltersData.recruitingCandidateLevel = data;
  },
  ["SET_GENERIC_CANDIDATE_TYPE"](state, data) {
    state.allFiltersData.candidateType = data;
  },
  ["SET_GENERIC_STUDENT_TYPE"](state, data) {
    state.allFiltersData.studentType = data;
  },
  ["SET_GENERIC_COURSE_TEST_TYPE"](state, data) {
    state.allFiltersData.courseType = data
  },
  ["SET_GENERIC_ORG_DEPARTMENT"](state, data) {
    state.allFiltersData.orgDepartment = data
  },
  ["SET_GENERIC_COURSE_LEVEL"](state, data) {
    state.allFiltersData.courseLevel = data;
    if (state.filterDependencyObjForAllSchemas[state.currentPageSchema]?.courseLevel?.defaultEntries) {
      state.filterDependencyObjForAllSchemas[state.currentPageSchema]['courseLevel']['defaultEntries'] = data
    }
  },
  ["SET_GENERIC_HEALTH_SECTOR"](state, data) {
    state.allFiltersData.healthSector = data;
  },
  ["SET_GENERIC_COURSE_STUDY_MODE"](state, data) {
    state.allFiltersData.studyMode = data
  },
  ["SET_GENERIC_CPD_COURSE"](state, data) {
    state.allFiltersData.cpdCourse = data;
  },
  ["SET_GENERIC_JOB_STATUS"](state, data) {
    state.allFiltersData.jobStatus = data;
  },
  ["SET_GENERIC_LOCATION_LANGUAGE"](state, data) {
    state.allFiltersData.locationLanguage = data;
  },
  ["SET_GENERIC_LOCATION_COUNTRY_INFO"](state, data) {
    state.allFiltersData.locationCountryInfo = data;
  },
  ["SET_GENERIC_LOCATION_REGION"](state, data) {
    state.allFiltersData.locationRegion = data;
  },
  ["SET_GENERIC_SPECIALITY"](state, data) {
    state.allFiltersData.speciality = data;
  },
  ["SET_GENERIC_AHP_SPECIALITY"](state, data) {
    state.allFiltersData.AHPSpeciality = data;
  },
  ["SET_GENERIC_SKILL"](state, data) {
    state.allFiltersData.skill = data;
  },
  ["SET_GENERIC_SUB_SPECIALITY"](state, data) {
    state.allFiltersData.subSpeciality = data;
  },
  ["SET_GENERIC_SPECIAL_INTEREST"](state, data) {
    state.allFiltersData.specialInterest = data;
  },
  ["SET_GENERIC_QUALIFICATION"](state, data) {
    state.allFiltersData.qualification = data;
  },
  ["SET_GENERIC_GENDER"](state, data) {
    state.allFiltersData.gender = data;
  },
  ["SET_GENERIC_LICENSING_AUTHORITY"](state, data) {
    state.allFiltersData.licensingAuthority = data;
  },
  ["SET_GENERIC_FETCHING"](state, data) {
    state.isFetchingGenericFilter = data;
  },
  ["SET_GENERIC_RETIRED_CLINICIAN"](state, data) {
    state.allFiltersData.retiredClinician = data;
  },
  ["SET_GENERIC_VOLUNTEER"](state, data) {
    state.allFiltersData.volunteer = data;
  },
  ["SET_GENERIC_LICENCE"](state, data) {
    state.allFiltersData.licence = data;
  },
  ["SET_GENERIC_ACTIVE"](state, data) {
    state.allFiltersData.active = data;
  },
  ["SET_GENERIC_IS_STUDENT"](state, data) {
    state.allFiltersData.isStudent = data;
  },
  ["SET_GENERIC_IS_PROFESSIONAL"](state, data) {
    state.allFiltersData.isProfessional = data;
  },
  ["SET_GENERIC_PUBLISHED"](state, data) {
    state.allFiltersData.published = data;
  },
  ["SET_GENERIC_DELETED"](state, data) {
    state.allFiltersData.deleted = data;
  },
  ["SET_GENERIC_AVAILABLE"](state, data) {
    state.allFiltersData.available = data;
  },
  ["SET_GENERIC_AVAILABILITY_TYPE"](state, data) {
    state.allFiltersData.availabilityType = data;
  },
  ["SET_GENERIC_JOB_AVAILABILITY_TYPE"](state, data) {
    state.allFiltersData.jobAvailability = data;
  },
  ["SET_GENERIC_CONTRACT_TYPE"](state, data) {
    state.allFiltersData.contractType = data;
  },
  ["SET_GENERIC_NATIONALITY_INFO"](state, data) {
    state.allFiltersData.nationalityInfo = data;
  },
  ["SET_FAVOURITE_FILTER"](state, data) {
    state.favouriteFilterList = data;
  },
  ["RESET_FAVOURITE_FILTER"](state) {
    state.favouriteFilterList = []
  },
  ["SET_SELECTED_FILTER_UPDATED"](state, data) {
    state.isSelectedFilterUpdated = data
  },
  ["SET_GENERIC_NHS_BAND"](state, data) {
    state.allFiltersData.nhsBand = data;
  },
  ["SET_GENERIC_CLASSIFICATION_LOCATIONS"](state, data) {
    state.allFiltersData.qualificationClassificationCountries = data;
  },
  ["SET_GENERIC_QUALIFICATION_CLASSIFICATION_LEVEL"](state, data) {
    state.allFiltersData.qualificationLevel = data;
    if (state.filterDependencyObjForAllSchemas[state.currentPageSchema]?.qualificationLevel?.defaultEntries) {
      state.filterDependencyObjForAllSchemas[state.currentPageSchema]['qualificationLevel']['defaultEntries'] = data
    }
  },
  ["SET_JOB_GROUP_LIST"](state, data) {
    state.allFiltersData.jobGroups = data?.map((item) => {
      return {
        label: item.group_name,
        code: item.group_id,
      }
    });
  },
  ["SET_GENERIC_COURSE_TITLE"](state, data) {
    state.allFiltersData.courseTitle = data;
  },
  ["SET_GENERIC_IS_SPONSORED"](state, data) {
    state.allFiltersData.isSponsored = data;
  },
  ["SET_GENERIC_ELIGIBLE"](state, data) {
    state.allFiltersData.eligible = data;
  },
  ["SET_GENERIC_FULLPARTTIME"](state, data) {
    state.allFiltersData.fullPartTime = data;
  },
};

const computeFilterDependency = (filterData, state) => {
  let allSchemaDependecyTree = {};
  // TODO:: make this list dynamic so that all filter schemas are included
  const schemas = [
    "jobBoardSchema",
    "jobBoardSchemaForCandidate",
    "jobFilter",
    "candidateListSchema",
    "newCandidateListSchema",
    "candidateListFacilityAdminSchema",
    "jobBoardSchemaForParentOrg",
    "candidateListSchemaForParentOrg",
    "candidateListSchemaForTFW",
    "candidateListSchemaForIms",
    "candidateListUKSchema",
    "candidateListPertempsSchema",
    "jobBoardPertempsSchema",
    "courseListSchemaForIMS",
    "courseListSchemaForSLC",
  ]
  schemas.forEach((val) => {
    const dependecyObj = {};
    if (state[val]) {
      for (let [key, value] of Object.entries(state[val])) {
        dependecyObj[key] = {
          defaultEntries: filterData[key],
        };
        if (value.dependsOn && value.dependsOn.length !== 0) {
          value.dependsOn.forEach((parentFilter) => {
            dependecyObj[key][parentFilter] = _.groupBy(
              filterData[key],
              "typeId"
            );
          });
        }
      }
    }
    allSchemaDependecyTree[val] = dependecyObj;
  });
  return allSchemaDependecyTree;
};

function cpd_points() {
  const res = [
    { code: 'all', label: "All Courses" },
    { code: 'all_cpd', label: "All CPD Courses" },
    { code: 'all_non_cpd', label: "Non CPD Courses" },
  ]
  for (let i = 1; i <= 250; i += 10) {
    let startRange = i;
    let endRange = i + 9;
    res.push({
      code: `${startRange}-${endRange}`,
      label: `${startRange}-${endRange}`
    });
  }
  return res;
}

export default {
  state,
  getters,
  actions,
  mutations,
};
